// const { $ } = require("video.js/dist/types/utils/dom");

var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
  winWidth = $(window).width(),
  winHeight = $(window).height();

$(function () {
  browserDetect();
});


$(window).on('resize orientationchange', function () {
  // Do on resize
  winWidth = $(window).width(),
  winHeight = $(window).height();
});

$(window).on('scroll', function () {
  //Do on Scroll
});

$(document).keyup(function (e) {
  if (e.keyCode == 27) {
    //Do on ESC press
  }
});
AOS.init();

function browserDetect() {
  navigator.sayswho = (function () {
    var ua = navigator.userAgent,
      tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  })();
  $('body').addClass(navigator.sayswho);
}


$(document).ready(function () {
  // animateWords();
  
  dataTrim();
  ChangeToSvg();
  formValidation();

});


function dataTrim() {
  var ellipsis = "...";
  $("[data-trim]").each(function () {
    var text = $(this).html();
    var charLimit = parseInt($(this).attr("data-trim"));
    $(this).html(TrimLength(text, charLimit));
    $(this).addClass("is--trimmed");
  });

  function TrimLength(text, maxLength) {
    text = $.trim(text);
    if (text.length > maxLength) {
      text = text.substring(0, maxLength - ellipsis.length);
      return text.substring(0, text.lastIndexOf(" ")) + ellipsis;
    } else return text;
  }
}

function ChangeToSvg() {
  $("img.js-tosvg").each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    $.get(
      imgURL,
      function (data) {
        var $svg = $(data).find("svg");
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " insvg");
        }
        $svg = $svg.removeAttr("xmlns:a");
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        $img.replaceWith($svg);
      },
      "xml"
    );
  });
}


// Form Field Validation Start 

function formValidation() {
  const submitBtn = document.querySelectorAll(".Formsubmit");

  // Loop through each submit button
  submitBtn.forEach(function (button) {
    button.addEventListener('click', function (event) {
      var form = event.target.closest('form'); // Get the parent form of the clicked button
      var isValid = true;

      $(form).find(".form-row-dropdown, .form-fields-row").each(function () {
        var $select = $(this).find("select");
        var $errorMessage = $(this).find(".error");
        var $select2Container = $(this).find(".select2-container");

        if ($select.val() == null || $select.val() == "1") {
          isValid = false;
          $select2Container.addClass("error-border");
          $errorMessage.show();
        } else {
          $select2Container.removeClass("error-border");
          $errorMessage.hide();
        }
      });

      // Check input fields with the required class within the current form
      $(form).find(".required").each(function () {
        if ($(this).val().trim() === "") {
          isValid = false;
          $(this).addClass("error-border");
          $(this).siblings('.error').show();
        } else {
          $(this).removeClass("error-border");
          $(this).siblings('.error').hide();
        }
      });

      if (!isValid) {
        event.preventDefault(); // Prevent form submission if validation fails
      }
    });
  });

  // 
  var togglePasswordIcons = document.querySelectorAll('.eye-icon');
  var togglePasswordSlashIcons = document.querySelectorAll('.eye-slash-icon');

  togglePasswordIcons.forEach(function (icon) {
    icon.addEventListener('click', function () {
      togglePasswordVisibility(icon);
    });
  });

  togglePasswordSlashIcons.forEach(function (Slashicon) {
    Slashicon.addEventListener('click', function () {
      togglePasswordVisibility(Slashicon);
    });
  });

  function togglePasswordVisibility(clickedIcon) {
    var passwordField = clickedIcon.parentNode.querySelector('.password-field');
    var relatedIcon = clickedIcon.classList.contains('eye-icon') ?
      clickedIcon.nextElementSibling :
      clickedIcon.previousElementSibling;

    if (passwordField.type === 'password') {
      passwordField.type = 'text';
      clickedIcon.style.display = 'none';
      relatedIcon.style.display = 'block';
    } else {
      passwordField.type = 'password';
      clickedIcon.style.display = 'none';
      relatedIcon.style.display = 'block';
    }
  }
}

$('.slider').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  arrows: false,
  autoplaySpeed: 2000,
  infinite: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});
	
$('.js-partnerslider').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  arrows: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    // {
    //   breakpoint: 1280,
    //   settings: {
    //     slidesToShow: 3,
    //     slidesToScroll: 1
    //   }
    // },
    {
      breakpoint: 992,
      settings: {
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  
});

$('.clientsSlider').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  arrows: false,
  autoplaySpeed: 2000,
  infinite: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

// window.onscroll = function() {
//   var element = document.getElementById('myElement');
//   if (document.documentElement.scrollTop > 100) {
//     element.classList.add('scrolled');
//   } else {
//     element.classList.remove('scrolled');
//   }
// };
document.querySelectorAll('.openbtn').forEach(function (btn) {
  btn.addEventListener('click', function () {
      this.classList.toggle('active');
      
      // Toggle class on the body element
      document.body.classList.toggle('active');
      
      // Toggle class on elements with class 'menu-items'
      document.querySelectorAll('.menu-items').forEach(function (menuItem) {
          menuItem.classList.toggle('active');
      });
  });
});
function smoothScroll(event, sectionId) {
  event.preventDefault();

  const targetElement = document.getElementById(sectionId);
  const offset = 100; // Define the offset in pixels

  const elementPosition = targetElement.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - offset;

  window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
  });

  removeClassOnSmallScreen();
}
function removeClassOnSmallScreen() {
  if (window.innerWidth < 992 && document.body) {
    document.body.classList.remove('active');
  }
  var menuItems = document.querySelectorAll('.menu-items');
    menuItems.forEach(function(item) {
      item.classList.remove('active');
    });
  var openbtn = document.querySelectorAll('.openbtn');
  openbtn.forEach(function(item) {
      item.classList.remove('active');
    });
}

// Call the function initially to handle the class removal on page load
document.addEventListener('DOMContentLoaded', removeClassOnSmallScreen);

// Listen for window resize event to handle changes in screen size
window.addEventListener('resize', removeClassOnSmallScreen);

const player = new Plyr('#player');

$(document).ready(function() {
  $('.select2').select2({
    minimumResultsForSearch: Infinity,
    placeholder: "Please Select",
  });
});

$(document).ready(function() {
  var $element = $('.header-section');
  var $element2 = $('.page-wrapper');
  var elementHeight = $element.height();
  $element2.css('padding-top', elementHeight);
});